<template>
  <!-- 我的银行卡 -->
  <div class="mybankcard">

    <div class="hasbank" v-if="bankLists!=[]" style="height: 100%">
      <div
        v-for="(item,index) in bankLists" :key="index"
        class="bank-item"
        :style="{'background':item.bankBackColor,'top':index*100+'px'}"
      >
        <div class="item-cont">
          <div class="cont-top">
            <div class="iconLogo"><p><img :src="item.logo" alt="" /></p><span>{{item.openBankName}}</span></div>
            <span>**** {{item.bankAccount4}}</span>
          </div>
          <p class="cont-down">
            <span class="star">*****</span><span class="english">UnionPay</span>
          </p>
        </div>
      </div>
       <div class="nocard" v-if="bankLists.length==0">
        <img src="./img/bank.png" alt="" />
        <p class="title">暂无绑定银行卡</p>
        <!-- <p class="addbank" @click="addAccount"><span>+</span> 添加银行卡</p> -->
      </div>
      <!-- <div class="advice" v-if="bankLists!=[]">
        <span class="weightText">温馨提醒：</span>
        <span class="normalText"
          >银行卡将作为您借款及还款使用，请妥善保管。</span
        >
      </div> -->
    </div>

    <!-- <div class="bankadd">
            <p class="add_bank">+ 添加银行卡</p>
            <p class="last">仅支持添加储蓄卡</p>
    </div> -->
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import APP from '@/utils/App'
import API from '../../server/api'
import { routerTag } from '../../utils/index'
export default {
  setup () {
    const state = reactive({ bankLists: [] })
    // 查看银行卡列表
    const bankList = async () => {
      const bankListData = await API.getMycardList()
      // console.log(bankListData.result, 'Hhh')
      if(bankListData.code==200){
        state.bankLists=bankListData.result;
      }
      // console.log(state.bankLists, '银行卡')
    }
    const addAccount = () => {
      APP.JUMP_TO(`${routerTag}addAccount`)
    }
    onMounted(() => {
      state.userId = localStorage.getItem('userId')
      state.creditNo = localStorage.getItem('creditNo')
      bankList()
      APP.SET_TITLE('我的银行卡')
    })

    return {
      addAccount,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" src="./index.less" scoped></style>
